<template>
  <v-container fluid class="fill-height ma-0 pa-0">
    <hospitals-clinics-component />
    <work-together-component />
    <footer-component />
  </v-container>
</template>

<script>
import HospitalsClinicsComponent from "@/components/hospitals-clinics/HospitalsClinicsComponent.vue";
import WorkTogetherComponent from "@/components/hospitals-clinics/WorkTogetherComponent.vue";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent.vue";

export default {
  name: "HospitalsClinics",
  title: "Hospitals and Clinics | Baja California Health Tourism",
  components: {
    HospitalsClinicsComponent,
    WorkTogetherComponent,
    FooterComponent,
  },
};
</script>
